import React, { useContext, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import LandingView from 'views/landing'
import FilterView from 'views/filter'
// import InterstitialView from 'views/interstitial'
import NotFoundView from 'views/notFound'
import { AppContext } from './state'
import Message from './components/message'
import { IPlatesJson } from 'state/types'
import FaqsView from 'views/faq'
import PrivacyView from 'views/privacy'
import TermsUseView from 'views/termsuse'
import BizPlatesView from 'views/bizplates'
import BizGuideView from 'views/bizguide'
import ContactUsView from 'views/contactus'
import CollectionView from 'views/collection'
import ThankyouView from 'views/thankyou'
import EoiView from 'views/eoi'
import ThankyouEoiView from 'views/thankyoueoi'

interface RouteProperties {
  path: string
  component: React.FunctionComponent<any>
  exact: boolean
}

interface Routes {
  [key: string]: RouteProperties
}

export const routes: Routes = {
  index: {
    path: '/',
    component: LandingView,
    exact: true,
  },
  filter: {
    path: '/choose-your-style',
    component: FilterView,
    exact: true,
  },
  series: {
    path: '/choose-your-style/:series',
    component: FilterView,
    exact: false,
  },
  // interstitial: {
  //   path: '/lets-do-this',
  //   component: InterstitialView,
  //   exact: true,
  // },
  faq: {
    path: '/PlatesFAQs',
    component: FaqsView,
    exact: true,
  },
  privacy: {
    path: '/Privacy',
    component: PrivacyView,
    exact: true,
  },
  terms: {
    path: '/TermsUse',
    component: TermsUseView,
    exact: true,
  },
  bizTerms: {
    path: '/BizPlatesTerms',
    component: BizPlatesView,
    exact: true,
  },
  bizGuide: {
    path: '/BizPlatesGuide',
    component: BizGuideView,
    exact: true,
  },
  contactUs: {
    path: '/ContactUs',
    component: ContactUsView,
    exact: true,
  },
  thankyou: {
    path: '/Thankyou',
    component: ThankyouView,
    exact: true,
  },
  eoi: {
    path: '/Eoi',
    component: EoiView,
    exact: true,
  },
  thankyoueoi: {
    path: '/ThankyouEoi',
    component: ThankyouEoiView,
    exact: true,
  },
  collection: {
    path: '/Collection',
    component: CollectionView,
    exact: true,
  },
}

const App: React.FC = () => {
  const state = useContext(AppContext)

  useEffect(() => {
    state.getPlatesJSON().then((platesJson: IPlatesJson) => {
      state.setPlatesJson(platesJson)
    })
  }, [state])

  return (
    <>
      {state.error && <Message type='error'>{state.error}</Message>}
      {state.message && <Message type='info'>{state.message}</Message>}
      <Switch>
        {Object.values(routes).map(route => (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}
        <Route
          render={props =>
            process.env.NODE_ENV === 'production' ? (
              <Redirect to={routes.index.path} />
            ) : (
              <NotFoundView {...props} />
            )
          }
        />
      </Switch>
    </>
  )
}

export default App
