import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { parse as parseQuery } from 'query-string'
import { routes } from 'App'
import Header from 'components/header'
import Button from 'components/button'
import Step from 'components/step'
import styles from './interstitial.module.scss'
import classNames from 'classnames'

const buildTrelisQueryString = (searchString: string): string => {
  let queryString = ''
  /**
   * We only care about the `type` paremeter in the query string.
   * However, a query string can be a `string` or `string[]`.
   * Example:
   *   ?type=string1&type=string2&type=string3
   *   ?type=string1,string2
   *
   * In either case, we only deal with the **first** item.
   * All other paremeters are discarded when we forward to Trelis.
   *
   * Nice to have: Check if `type` is one that we recognise based on the Plates JSON data.
   */
  const query = parseQuery(searchString)
  if (query.type && query.type.length) {
    const { type } = query
    if (Array.isArray(type)) {
      queryString = `?type=${type[0]}`
    } else {
      const typeStringArray = type.split(',')
      if (typeStringArray.length > 1) {
        queryString = `?type=${typeStringArray[0]}`
      } else {
        queryString = `?type=${type}`
      }
    }
  }
  return queryString
}

interface InterinsicMatchParams {
  series: string,
  location: string
}

interface Interstitial extends RouteComponentProps<InterinsicMatchParams> {
  mainSeries: string;
}

const Interstitial: React.FC<Interstitial> = (props: Interstitial, {location, history, match}: RouteComponentProps) => {

//const Interstitial: React.FC<RouteComponentProps> = ({ location, mainSeries}: RouteComponentProps) => {
  //console.log('locarion---',typeof location, location)
  const mainSeries = props.mainSeries;
  // const location = mainSeries;
  // const trelisQueryString = buildTrelisQueryString(location)
 let condition = false;
 let choose_styleClass = "";
 let choose_howItWorksCheckLeftClass = "";
 let mainLiClasses = "";
  if(mainSeries !== ''){
    condition = true;
    choose_styleClass = classNames({
      [styles.choose_style]: (condition),
    })
    mainLiClasses = classNames({
      [styles.mainHowItWorksCheckLI] : (condition),
     })
  }
  if(mainSeries === '/choose-your-style'){
    condition = true;
    choose_howItWorksCheckLeftClass = classNames({
      [styles.choose_howItWorksCheckLeft]: (condition),
    })
  }
console.log('mainSeries--interstitial---',mainSeries)

  return (
    <>

      <main role='main' style={{textAlign:"center"}} className={choose_styleClass+' '+choose_howItWorksCheckLeftClass}>
        <h2 className={styles.h2Howitworks}>How it works</h2>
        { mainSeries === 'nostalgia' && (
          <div>
            <span style={{fontSize: "1.5rem"}}>We're now taking expressions of interest for our new Nostalgia plate series which will be available to buy from the end of March.</span><br/>
            <span style={{fontSize: "1.5rem"}}>Reserve your preferred Nostalgia plate combination (if available) by submitting an EOI.</span><br/>
            <span style={{fontSize: "1.5rem"}}>Once we receive your EOI, one of our friendly PlatesWA team will review and confirm availability of your plate combination. When the plates are ready for production, we'll contact you for payment.</span><br/><br/>
            <ul style={{fontSize: "1.5rem", margin: "0"}}>
              <li> Step 1: Submit your EOI for your preferred combinations</li>
              <li>Step 2: We will review and confirm availability</li>
              <li>Step 3: Once plates are ready for ordering, we will contact you for payment.</li>
            </ul>
            <br/><br/>
            <a
            href={`#${routes.filter.path}`}
              rel='noopener noreferrer'
              className={styles.link}
              style={{color:"#000000", textDecoration:"null"}}
              >
              <strong>Back to all plates</strong>
            </a>
            <br/><br/>
            <Button
                size='large'
                as='link'
                to={`${routes.eoi.path}`}
                target= '_blank'
              >
              Register EOI
          </Button>
          </div>
        )}
        { mainSeries !== 'nostalgia' && (
          <ol className={styles.stepList+' '+mainLiClasses}>
            <Step step={1} icon='cart' mainSeries={mainSeries} location={location} history={history as any} match={match as any} >
              Order online
            </Step>
            <Step step={2} icon='thumbsup' mainSeries={mainSeries} location={location} history={history as any} match={match as any}>
              We review &amp; make
              <br />
              your plates
            </Step>
            <Step step={3} icon='route' mainSeries={mainSeries} location={location} history={history as any} match={match as any}>
              Collect the plates from us when they are ready
            </Step>
          </ol>
        )}
          { mainSeries !== '/choose-your-style' && mainSeries !== 'nostalgia' && (
            <div className={styles.mainHowWorkSec}>
              <h2 className={styles.h2Howitworks}>What you&rsquo;ll need</h2>
              <ul className={styles.stepList}>
                <Step icon='id' mainSeries={mainSeries} location={location} history={history as any} match={match as any} step={0}>Your valid drivers licence</Step>
                <Step icon='cards' mainSeries={mainSeries} location={location} history={history as any} match={match as any} step={0}>Credit/debit card</Step>
              </ul>
              { mainSeries === '' && (
              <div className={styles.action}>
                <Button
                  as='link'
                  //to={`${process.env.REACT_APP_TRELIS_URL}/${trelisQueryString}`}
                  to={`${routes.filter.path}`}
                  rel='noopener noreferrer'
                  size='large'
                >
                  Let's do this
                </Button>
              </div>
              )}
            </div>
          )}
      </main>
    </>
  )
}

export default Interstitial
