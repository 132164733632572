import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { routes } from 'App'
import { toMoney } from 'utils'
import Button from 'components/button'
import ColorCheckbox from 'components/color-checkbox'
import styles from './plate.module.scss'
import helperStyles from 'styles/_helpers.module.scss'
import { Plate as PlateData } from 'utils'

interface PlateImageSet {
  low?: string
  high?: string
}

const plateSrcSet = (imageSet: PlateImageSet): string | undefined => {
  const hasLowImage = imageSet.low && imageSet.low.length
  const hasHighImage = imageSet.high && imageSet.high.length
  const hasCompleteSet = hasLowImage && hasHighImage

  if (hasLowImage || hasHighImage) {
    let srcSet = ''
    if (hasLowImage) srcSet += `${process.env.REACT_APP_DOT_SERVER_URL}${imageSet.low} 1x`
    if (hasCompleteSet) srcSet += ', '
    if (hasHighImage) srcSet += `${process.env.REACT_APP_DOT_SERVER_URL}${imageSet.high} 2x`
    return srcSet
  }
  return undefined
}

export interface Plate {
  plate: PlateData
  compact?: boolean
  expanded?: boolean
  className?: string
  mainSeries:string
}

const Plate: React.FC<Plate> = ({ plate, compact, expanded, className, mainSeries }: Plate) => {
  const rootClasses = classNames(
    styles.plate,
    {
      [styles.hover]: expanded,
    },
    className
  )

  const titleClasses = classNames(styles.title, {
    [helperStyles.visuallyHidden]: compact,
  })

  const labelClasses = classNames(styles.priceLabel, {
    [helperStyles.visuallyHidden]: compact,
  })

  const priceClasses = classNames(styles.price, {
    [styles.compact]: compact,
  })

  const isStoreFrontOnly =
    plate.store_front && !!plate.store_front.text.length && !!plate.store_front.text1.length && !!plate.store_front.text2.length && !!plate.store_front.url.length

    var mainClassExpandHover = "";
    if(mainSeries === 'platinum'){
      mainClassExpandHover = "mainClassExpandHover";
    }
    var mainClassAvaialbleStore = "";
    if(mainSeries === '/choose-your-style'){
      mainClassAvaialbleStore = "mainClassAvaialbleStore";
    }

  return (
    <div>
      <article className={rootClasses}>
        <figure className={styles.image}>
          <Link to={`${routes.filter.path}/${plate.series}`} className={styles.imageLink}>
            <img
              src={`${process.env.REACT_APP_DOT_SERVER_URL}${plate.images.high}`}
              srcSet={plateSrcSet(plate.images)}
              alt={plate.name}
              title={compact ? plate.name : undefined}
            />
          </Link>
        </figure>
        <div className={styles.copy}>
          <h3 className={titleClasses}>{plate.name}</h3>
          {plate.start_price && (
            <p className={priceClasses}>
              <span className={labelClasses}>From</span>{' '}
              <strong>{toMoney(plate.start_price, { decimals: 2 })}</strong>
            </p>
          )}
          {isStoreFrontOnly && mainSeries !== 'nostalgia' && plate.store_front.url !== '/Eoi' && (
            <p className={styles.mainClassAvaialbleStore}><img src={`${process.env.REACT_APP_DOT_SERVER_URL}`+'/PlatesWA/exclamation.png'} width="15" style={{marginRight: "8px", marginBottom: "-2px"}} alt="" /><b>Available in-centre only</b></p>
          )}
          {isStoreFrontOnly && (mainSeries === 'nostalgia' || mainSeries === '' || plate.store_front.url === '/Eoi') && (
            <p className={styles.mainClassAvaialbleStore}><img src={`${process.env.REACT_APP_DOT_SERVER_URL}`+'/PlatesWA/exclamation.png'} width="15" style={{marginRight: "8px", marginBottom: "-2px"}} alt="" /><b>Available through EOI</b></p>
          )}
        </div>
        <div className={styles.expandable}>
          <dl className={styles.details+ ' ' +styles.mainClassExpandHover}>
            {plate.series_colors && !!plate.series_colors.length && (
              <div>
                <dt className={styles.label}>Available colours</dt>
                <dd>
                  {Object.values(plate.series_colors).map(({ name, value }, index) => (
                    <ColorCheckbox
                      color={{ name, value }}
                      disabled
                      checked={plate.colors.includes(value)}
                      key={`color${index}-${name}`}
                    />
                  ))}
                </dd>
              </div>
            )}
            <div className={styles.labelInline}>
              <dt>Slimline available?</dt>
              <dd>{plate.slimline ? 'Yes' : 'No'}</dd>
            </div>
          </dl>
          <div className={styles.actions}>
          {!mainSeries && !isStoreFrontOnly && (
              <>
                <Button
                  as='link'
                  to={`${routes.filter.path}/${plate.series}`}
                  rel='noopener noreferrer'
                  size = 'large'
                >
                  Browse style
                </Button>
              </>
            )}
            {/* && plate.series != 'bizplate' */}
            {mainSeries && !isStoreFrontOnly && plate.showSeries && (
              <>
                <Button
                  as='anchor'
                  to={`${process.env.REACT_APP_TRELIS_URL}/?type=${plate.trelis_type}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  size = 'large'
                >
                  Create Plate
                </Button>
                {/* <Link to={`${routes.filter.path}/${plate.series}`} className={styles.link}>
                  <strong>Browse series</strong>
                </Link> */}
              </>
            )}
            {isStoreFrontOnly && mainSeries !== 'nostalgia' && plate.store_front.url !== '/Eoi' && (
              <>
                {plate.store_front.text && <p>{plate.store_front.text}</p>}
                {plate.store_front.url && (
                  <a
                    href={`${plate.store_front.url}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={styles.link}
                  >
                    <strong>Find centres</strong>
                </a>
                )}
              </>
            )}
            {isStoreFrontOnly && (mainSeries === 'nostalgia' || mainSeries === '' || plate.store_front.url === '/Eoi') && (
              <>
              {plate.store_front.text && <p>{plate.store_front.text}</p>}
              <a
                href={`#${plate.store_front.url}`}
                target='_blank'
                rel='noopener noreferrer'
                className={styles.link}
              >
                <strong>Register EOI</strong>
            </a>
              </>
            )}
          </div>
        </div>
      </article>
    </div>
  )
}

export default Plate
